import React from 'react';

import { SantaClausDetailsItem, SantaClausDetailsItemProps } from './santa-claus-details-item';

interface SantaClausDetailsProps {
  mainContent: React.ReactNode;

  secondaryContent: React.ReactNode;

  items: SantaClausDetailsItemProps[];
}
export const SantaClausDetails: React.FC<SantaClausDetailsProps> = ({ mainContent, secondaryContent, items }) => {
  return (
    <div className="flex flex-col lg:flex-row lg:gap-[90px] mb-10 lg:mb-4.875 text-sm">
      <div className="text-sm leading-[20px] lg:text-md lg:leading-[25px] lg:w-[550px]">
        {mainContent && <div className="mb-8 lg:mb-3.75">{mainContent}</div>}

        <div className="hidden lg:block">{secondaryContent}</div>
      </div>

      <div className="flex lg:w-[550px] flex-col gap-[13px] mb-10 lg:mb-0">
        {items.map(({ text, icon }, idx) => (
          <SantaClausDetailsItem key={idx} icon={icon} text={text} number={idx + 1} />
        ))}
      </div>

      <div className="lg:hidden">{secondaryContent}</div>
    </div>
  );
};
