import React from 'react';

export interface SantaClausDetailsItemProps {
  number?: number;

  icon?: string;

  text: string;
}

export const SantaClausDetailsItem: React.FC<SantaClausDetailsItemProps> = ({ number, icon, text }) => {
  return (
    <div className="flex items-center space-x-[25px] py-[25px] px-[32px] rounded-[9px] bg-[#E1D390]">
      {!icon && (
        <span className="shrink-0 w-[60px] h-[60px] text-center text-[#096142] rounded-full border-2 text-xl leading-[60px] font-Kharkiv-Tone">
          {number}
        </span>
      )}

      {icon && <img className="shrink-0 w-[60px] h-[60px] select-none" src={icon} />}

      <span className="text-sm lg:text-base">{text}</span>
    </div>
  );
};
