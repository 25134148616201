import React from 'react';

import { SantaClausFooter } from '@widgets/santa-claus/landing/santa-claus-footer';
import { SantaClausHeader } from '@widgets/santa-claus/landing/santa-claus-header';
import { SantaClausDetails } from '@widgets/santa-claus/landing/santa-claus-details';
import { SantaClausPartners } from '@widgets/santa-claus/landing/santa-claus-partners';
import { SantaClausWrapper } from '@widgets/santa-claus/landing/santa-claus-wrapper';

import prizeIcon from '@assets/images/santa-claus-letter/santa-prize-icon.png';

const volunteerDetailsItems = [
  {
    icon: prizeIcon,
    text: 'This way you can become a virtual Santa’s helper, an Santa, and participate in a very special Christmas mission -  because every child in Ukraine is waiting for a Christmas miracle.',
  },
];

const SantaClausForeignVolunteerPage = () => {
  return (
    <div>
      <SantaClausHeader
        header={
          <p className="max-w-[250px] lg:max-w-[800px] text-lg lg:text-[52px] lg:leading-[52px]">
            Children in Ukraine have a Christmas wish! Will you be their Santa?
          </p>
        }
        background="bg-santa-claus-header-volunteer"
        backgroundColor="bg-[#00afef]"
        subheaderBackgroundColor="bg-[#f1aa00] santa-claus-subheader-foreign"
      >
        <p className="text-sm lg:text-md text-black mb-2 lg:mb-9">
          We all anticipate the Christmas holidays, make wishes, wait for miracles, and get joy out gift giving.
          However, for children in Ukraine, the war has meant that 2022 was unlike any other year. To bring back the joy
          of childhood, the team of the eDopomoga platform are looking for Santas, people who will bring back the
          holiday spirit and make the wishes of little dreamers come true. Each of us can become an Santa! All you need
          is a desire to bring joy to children.
        </p>
      </SantaClausHeader>
      <SantaClausWrapper>
        {/* <SantaClausPartners /> */}

        <SantaClausDetails
          mainContent={null}
          secondaryContent={
            <p>
              The dreamer will write a letter with his/her wish and a Christmas drawing and upload it to the eDopomoga
              platform. The Santa can then choose from these letters and fulfil the dream of one of the children. The
              Santa can buy a gift certificate for the little dreamer, so a child can choose a gift for themselves in a
              partner toy store.
            </p>
          }
          items={volunteerDetailsItems}
        />

        <SantaClausFooter
          text="Applications can be closed from 1st of December 2022 to 14th of January 2023"
          buttonLink="/cabinet/volunteer?category=santa-claus-letter"
          buttonText="Become eSanta"
        />
      </SantaClausWrapper>
    </div>
  );
};

export default SantaClausForeignVolunteerPage;
