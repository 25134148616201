import { Button } from '@shared/ui/buttons/Button';
import React from 'react';

interface SantaClausFooterProps {
  text: string;

  buttonLink: string;

  buttonText: string;
}

export const SantaClausFooter: React.FC<SantaClausFooterProps> = ({ text, buttonLink, buttonText }) => {
  return (
    <div className="flex flex-col items-center text-center">
      <p className="text-xxs md:text-md text-[#8F8F8F] mb-4 md:mb-8">{text}</p>

      <Button as="link-in" variant="light-blue" to={buttonLink} className="self-stretch sm:self-center">
        {buttonText}
      </Button>
    </div>
  );
};
