import React from 'react';
import clsx from 'clsx';

interface SantaClausHeaderProps {
  background: string;

  backgroundColor?: string;

  subheaderBackgroundColor?: string;

  header: React.ReactNode;

  children: React.ReactNode;
}

export const SantaClausHeader: React.FC<SantaClausHeaderProps> = ({
  header,
  background,
  backgroundColor = 'bg-[#0E5032]',
  subheaderBackgroundColor = 'bg-[#810711]',
  children,
}) => {
  return (
    <div>
      <div
        className={clsx(
          'santa-claus-header font-Kharkiv-Tone bg-no-repeat px-4 py-[15px] lg:px-3.75 lg:py-[70px] font-normal text-[32px] leading-[32px] lg:text-5xl xl:text-10xl text-white',
          background,
          backgroundColor,
        )}
      >
        {header}
      </div>

      <div
        className={clsx(
          'santa-claus-subheader px-[15px] py-[30px] lg:px-8.375 lg:pt-[40px] lg:pb-[45px]  bg-santa-claus bg-no-repeat font-light text-sm md:text-md xl:text-[22px] text-white',
          subheaderBackgroundColor,
        )}
      >
        <div className="max-w-[335px] md:max-w-[550px]">{children}</div>
      </div>
    </div>
  );
};
