import React from 'react';

import SantaClausForeignVolunteerPage from './foreign-volunteer';

import { SantaClausFooter } from '@widgets/santa-claus/landing/santa-claus-footer';
import { SantaClausHeader } from '@widgets/santa-claus/landing/santa-claus-header';
import { SantaClausDetails } from '@widgets/santa-claus/landing/santa-claus-details';
import { SantaClausPartners } from '@widgets/santa-claus/landing/santa-claus-partners';
import { SantaClausWrapper } from '@widgets/santa-claus/landing/santa-claus-wrapper';

import prizeIcon from '@assets/images/santa-claus-letter/prize-icon.png';
import routeIcon from '@assets/images/santa-claus-letter/route-icon.png';
import shopIcon from '@assets/images/santa-claus-letter/shop-icon.png';

interface SantaClausVolunteerPageProps {
  pageContext: {
    language: string;
  };
}

const volunteerDetailsItems = [
  {
    icon: prizeIcon,
    text: 'самостійно купити подарунок та передати його дитині особисто при зустрічі;',
  },
  {
    icon: routeIcon,
    text: 'надіслати подарунок Новою поштою, яка на час дії проекту доставить подарунок дитині до 10 кг. своїм коштом на підконтрольну територію України;',
  },
  {
    icon: shopIcon,
    text: 'оплатити подарунковий сертифікат, а дитина вибере собі подарунок самостійно в іграшковому магазині партнерів.',
  },
];

const SantaClausVolunteerPage = ({ pageContext }: SantaClausVolunteerPageProps) => {
  if (pageContext?.language !== 'uk') {
    return <SantaClausForeignVolunteerPage />;
  }

  return (
    <div>
      <SantaClausHeader
        header={
          <>
            <p>єМрія!</p>
            <p>єМиколай?</p>
          </>
        }
        background="bg-santa-claus-header-volunteer"
      >
        <p className="mb-2 md:mb-9">
          Усі ми зазвичай готуємось до зимових свят, загадуємо бажання, чекаємо на дива та подарунки. Та 2022 рік був
          важким для всіх нас, а особливо для маленьких українців.
        </p>

        <p>
          Щоб повернути дітлахам свято та здійснити бажання маленьких мрійників, команда платформи єДопомога вирішила
          допомогти Миколаю та знайти йому помічників – єМиколаїв.
        </p>
      </SantaClausHeader>
      <SantaClausWrapper>
        {/* <SantaClausPartners /> */}

        <SantaClausDetails
          mainContent={<p>єМиколаєм може стати кожен! Потрібно лише прагнення здійснити бажання одного з малюків.</p>}
          secondaryContent={
            <div>
              <p className="font-bold mb-6">Як це працює:</p>

              <p className="mb-6">
                Мрійники пишуть листи зі своїми бажаннями, прикріпивши новорічний малюнок на платформі єДопомога. А
                єМиколай вибирає лист тієї дитини, якій він хоче зробити подарунок. Є кілька способів, як це здійснити.
              </p>

              <p>Долучайся до важливої місії – стань єМиколаєм! Адже кожна дитина України сьогодні чекає на диво.</p>
            </div>
          }
          items={volunteerDetailsItems}
        />

        <SantaClausFooter
          text="Заявки можна закрити з 1 грудня 2022 року по 14 січня 2023 року (включно)"
          buttonLink="/cabinet/volunteer?category=santa-claus-letter"
          buttonText="Стати єМиколаєм"
        />
      </SantaClausWrapper>
    </div>
  );
};

export default SantaClausVolunteerPage;
